<template>
  <SCBanner kind="error">
    <div>
      <div class="flex row">
        <DangerSignGlyph class="mr-2" />
        {{ title }}
      </div>
      <p class="ml-1 mt-2 font-normal">
        {{ subtitle }}
      </p>
    </div>
  </SCBanner>
</template>

<script>
import { defineComponent } from "vue";

import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import SCBanner from "@/components/molecules/banners/SCBanner.vue";

export default defineComponent({
  name: "ErrorBanner",
  components: { SCBanner, DangerSignGlyph },
  props: {
    type: String,
  },
  created() {
    switch (this.type) {
      case "pitr_restore":
        this.title = this.$t("pitr.error.title");
        this.subtitle = this.$t("pitr.error.subtitle");
        break;
    }
  },
});
</script>

<i18n>
  en:
    pitr:
      error:
        title: "An error occurred during the database restoration."
        subtitle: "We encountered an issue while restoring the database. For confirmation of its integrity and current status, please reach out to our support team."
  fr:
    pitr:
      error:
        title: "Une erreur est survenue lors de la restauration de la base de données."
        subtitle: "Nous avons rencontré un problème lors de la restauration de la base de données. Pour vérifier son intégrité et son état actuel, veuillez contacter notre équipe de support."

</i18n>
