<script>
import { ref, defineComponent } from "vue";

import { currentDB, currentDBClient } from "@/stores/current/db";

export default defineComponent({
  name: "MysqlConfiguration",
  setup() {
    const configurationList = ref([]);

    async function getConfigurationList() {
      const client = await currentDBClient();
      const db = currentDB();
      const { result } = await client.Database.mysqlListExtensions(db.id);

      configurationList.value = result.length > 0 ? result.split(",") : [];
    }

    getConfigurationList();

    return {
      configurationList: configurationList,
    };
  },
});
</script>
