export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "pitr": {
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred during the database restoration."])},
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We encountered an issue while restoring the database. For confirmation of its integrity and current status, please reach out to our support team."])}
          }
        }
      },
      "fr": {
        "pitr": {
          "error": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la restauration de la base de données."])},
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons rencontré un problème lors de la restauration de la base de données. Pour vérifier son intégrité et son état actuel, veuillez contacter notre équipe de support."])}
          }
        }
      }
    }
  })
}
