<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" :subtitle="$t('subtitle')">
        <template v-slot:buttons>
          <div class="min-w-max pl-1">
            <SCButton
              :disabled="!pitrEnabled || displayRunningBackupBanner"
              @click="pitrModalShow = true"
            >
              {{ $t("startPitr") }}
            </SCButton>
          </div>
        </template>
      </CardHeader>
    </template>
    <template #body>
      <div class="flex flex-row space-x-3">
        <div class="bg-scale-1 text-scale-4 w-12 h-12 p-2 rounded-lg">
          <CalendarGlyph fill="var(--scale-4)" />
        </div>
        <div class="flex flex-col">
          <div class="flex h-full">
            <div v-if="pitrEnabled" class="my-auto row flex">
              {{ $t("retention") }}
              <div class="font-medium ml-1">{{ retentionPeriod }}</div>
            </div>
            <div v-else class="my-auto row flex">
              {{ $t("nopitr") }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </Card>
  <PitrModal
    v-if="pitrModalShow"
    :firstPitrBackup="pitrDate"
    :callPitr="callPitr"
    :firstPitrHour="firstPitrHour"
    :firstPitrMinute="firstPitrMinute"
    :backupError="backupError"
    @close="closePitrModal()"
  ></PitrModal>
</template>

<script>
import { DateTime } from "luxon";
import { defineComponent } from "vue";

import CalendarGlyph from "@/components/atoms/glyphs/CalendarGlyph.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import PitrModal from "@/components/parts/db/backups/PitrModal.vue";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "PitrInformations",
  components: {
    CalendarGlyph,
    SCButton,
    CardHeader,
    Card,
    PitrModal,
  },
  mixins: [ModelsTranslation],
  props: {
    db: Object,
    hasBackupInPlan: Boolean,
    callPitr: Function,
    firstPitrHour: Number,
    firstPitrMinute: Number,
    backupError: String,
    displayRunningBackupBanner: Boolean,
  },
  emits: ["startBackupsConfiguration", "clearBackupError"],
  data() {
    return {
      pitrModalShow: false,
    };
  },
  computed: {
    pitrEnabled() {
      return !!this.db.first_pitr_backup;
    },
    pitrDate() {
      const pitrDate = DateTime.fromISO(this.db.first_pitr_backup);
      const sevenDaysAgo = DateTime.now().minus({ days: 7 });

      return pitrDate > sevenDaysAgo ? pitrDate : sevenDaysAgo;
    },
    retentionPeriod() {
      const now = DateTime.now();
      const diffInDays = Math.round(now.diff(this.pitrDate, "days").days);

      if (diffInDays === 0) {
        return this.retentionHours(
          Math.round(now.diff(this.pitrDate, "hours").hours),
        );
      }

      return this.retentionDays(diffInDays);
    },
  },
  methods: {
    closePitrModal() {
      this.$emit("clearBackupError");
      this.pitrModalShow = false;
    },
    retentionDays(diffInDays) {
      return `${diffInDays} ${this.$t("day")}${diffInDays > 1 ? "s" : ""}`;
    },
    retentionHours(diffInhours) {
      if (diffInhours === 0) {
        return `< 1 ${this.$t("hour")}`;
      }

      return `${diffInhours} ${this.$t("hour")}${diffInhours > 1 ? "s" : ""}`;
    },
  },
});
</script>

<i18n>
en:
  title: "Point-In-Time Recovery"
  subtitle: "This database is continuously backed up, allowing it to be restored to a specific point in time."
  startPitr: "Start a PITR"
  retention: "Retention period: "
  day: "day"
  hour: "hour"
  nopitr: "Continuous backup is being activated. This process may take up to 24 hours."
 
fr:
  title: "Point-In-Time Recovery"
  subtitle: "Cette base de données est sauvegardée en continu, permettant sa restauration à un moment précis."
  startPitr: "Restauration PITR"
  retention: "Période de rétention : "
  day: "jour"
  hour: "heure"
  nopitr: "La sauvegarde en continu est en cours d'activation. Cette étape peut prendre jusqu'à 24 heures."
</i18n>
