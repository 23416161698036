export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "maintenanceInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database is being restored"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A backup is being restored. The operation may take from a few minutes to several hours depending on the size of your database. We recommend that you do not carry out any operations until the restoration has been completed."])}
      },
      "fr": {
        "maintenanceInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base de donnée est en cours de restauration"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un backup est en cours de restauration. L'opération peut durer de quelques minutes à plusieurs heures en fonction de la taille de votre base de données. Nous vous recommandons de ne pas effectuer d'opérations tant que la restauration n'est pas terminée."])}
      }
    }
  })
}
