export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "maintenanceInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The database has been restored"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The backup has been successfully restored. You can now access your database safely."])}
      },
      "fr": {
        "maintenanceInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La base de données a été restaurée"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le backup a été restaurée avec succès. Vous pouvez maintenant accéder à votre base de données en toute sécurité."])}
      }
    }
  })
}
