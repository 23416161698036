<template>
  <SCBanner kind="warning">
    <div>
      <div class="flex row">
        <ReloadGlyph class="mr-2" :animated="true" />
        {{ $t("maintenanceInProgress") }}
      </div>
      <p class="ml-1 mt-2 font-normal">
        {{ $t("text") }}
      </p>
    </div>
  </SCBanner>
</template>

<script>
import { defineComponent } from "vue";

import ReloadGlyph from "@/components/atoms/glyphs/ReloadGlyph.vue";
import SCBanner from "@/components/molecules/banners/SCBanner.vue";

export default defineComponent({
  name: "BackupRunningBanner",
  components: { SCBanner, ReloadGlyph },
  props: {
    items: Array,
    db: Object,
    dbMaintenance: Object,
  },
});
</script>

<i18n>
  en:
    maintenanceInProgress: "Database is being restored"
    text: "A backup is being restored. The operation may take from a few minutes to several hours depending on the size of your database. We recommend that you do not carry out any operations until the restoration has been completed."
  fr:
    maintenanceInProgress: "Base de donnée est en cours de restauration"
    text: "Un backup est en cours de restauration. L'opération peut durer de quelques minutes à plusieurs heures en fonction de la taille de votre base de données. Nous vous recommandons de ne pas effectuer d'opérations tant que la restauration n'est pas terminée."
</i18n>
