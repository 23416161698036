<template>
  <div ref="bannerContainerRef" class="absolute w-screen left-0 z-40">
    <MaintenanceRunningBanner v-if="showMaintenanceRunningBanner" />
    <MaintenanceScheduledBanner
      v-else-if="showMaintenanceScheduledBanner"
      :items="notifiedItems"
      :dbMaintenance="dbMaintenance"
      :db="db"
    />
    <BackupRunningBanner v-if="sessionStore.displayRunningBackupBanner" />
    <BackupFinishedBanner
      v-if="sessionStore.displayFinishedBackupBanner"
      @dismiss="sessionStore.displayFinishedBackupBanner = false"
    />
    <ErrorBanner
      v-if="sessionStore.errorBanner"
      :type="sessionStore.errorBanner"
    />
  </div>
  <div
    :style="{ height: bannerContainerHeight + 'px' }"
    class="relative z-0"
  ></div>
</template>

<script>
import { computed, defineComponent, ref, onMounted, onUnmounted } from "vue";

import BackupFinishedBanner from "@/components/organisms/banners/BackupFinishedBanner.vue";
import BackupRunningBanner from "@/components/organisms/banners/BackupRunningBanner.vue";
import ErrorBanner from "@/components/organisms/banners/ErrorBanner.vue";
import MaintenanceRunningBanner from "@/components/organisms/banners/MaintenanceRunningBanner.vue";
import MaintenanceScheduledBanner from "@/components/organisms/banners/MaintenanceScheduledBanner.vue";
import { useSessionStore } from "@/stores/session";

export default defineComponent({
  name: "DbBannersManager",
  components: {
    MaintenanceScheduledBanner,
    MaintenanceRunningBanner,
    BackupRunningBanner,
    BackupFinishedBanner,
    ErrorBanner,
  },
  props: {
    db: Object,
    dbMaintenance: Object,
    notifiedItems: Array,
    ongoingItems: Array,
  },
  setup(props) {
    const bannerContainerRef = ref(null);
    const bannerContainerHeight = ref(0);
    const showMaintenanceRunningBanner = computed(
      () => props.ongoingItems?.length > 0,
    );
    const showMaintenanceScheduledBanner = computed(
      () => props.notifiedItems?.length > 0,
    );
    const updateHeight = () => {
      if (bannerContainerRef.value) {
        bannerContainerHeight.value = bannerContainerRef.value.offsetHeight;
      }
    };
    const sessionStore = useSessionStore();
    let observer;

    onMounted(() => {
      updateHeight();
      observer = new ResizeObserver(updateHeight);
      observer.observe(bannerContainerRef.value);
    });

    onUnmounted(() => {
      if (observer && bannerContainerRef.value) {
        observer.unobserve(bannerContainerRef.value);
      }
    });

    return {
      showMaintenanceScheduledBanner,
      showMaintenanceRunningBanner,
      bannerContainerRef,
      bannerContainerHeight,
      sessionStore,
    };
  },
});
</script>
