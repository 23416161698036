import { setupPolling } from "@/lib/pinia/utils/polling";
import Client from "@/lib/scalingo/dbapi/client";
import { useSessionStore } from "@/stores/session";

type response = {
  operation: operation;
};

type operation = {
  created_at: string;
  finished_at: string;
  status: string;
  type: string;
  id: string;
  error: string;
};

export function importOperationMethods() {
  const setOperationBanner = (operation: operation) => {
    const sessionStore = useSessionStore();

    switch (operation.status) {
      case "finished":
        sessionStore.displayFinishedBackupBanner = true;
        break;
      case "error":
        sessionStore.errorBanner = operation.type;
        break;
      case "running":
        sessionStore.displayRunningBackupBanner = true;
        break;
    }
  };

  const fetchDbOperationById = async (client: Client, operation: operation) => {
    const clientResponse: response = await client.Database.getDatabaseOperation(
      operation.id,
    );

    if (clientResponse.operation.type === "pitr_restore") {
      setOperationBanner(clientResponse.operation);
      setupOperationPolling(client, clientResponse.operation);
    }
  };

  const setupOperationPolling = (client: Client, operation: operation) => {
    const stopFn = () => {
      return ["finished", "error"].includes(operation.status);
    };

    const refreshFn = async () => {
      fetchDbOperationById(client, operation);
    };

    const teardownFn = () => {
      const sessionStore = useSessionStore();

      sessionStore.displayRunningBackupBanner = false;
      setOperationBanner(operation);
    };

    setupPolling({
      id: operation.id,
      kind: "dbOperation",
      stopFn,
      refreshFn,
      teardownFn,
    });
  };

  const clearOperationBanner = () => {
    const sessionStore = useSessionStore();

    sessionStore.displayFinishedBackupBanner = false;
    sessionStore.errorBanner = "";
    sessionStore.displayRunningBackupBanner = false;
  };

  return {
    fetchDbOperationById,
    setupOperationPolling,
    clearOperationBanner,
  };
}
