<template>
  <SCBanner kind="success" :dismissible="true" @dismiss="$emit('dismiss')">
    <div>
      <div class="flex row">
        <CheckGlyph class="mr-2" />
        {{ $t("maintenanceInProgress") }}
      </div>
      <p class="ml-1 mt-2 font-normal">
        {{ $t("text") }}
      </p>
    </div>
  </SCBanner>
</template>

<script>
import { defineComponent } from "vue";

import CheckGlyph from "@/components/atoms/glyphs/CheckGlyph.vue";
import SCBanner from "@/components/molecules/banners/SCBanner.vue";

export default defineComponent({
  name: "BackupFinishedBanner",
  components: { SCBanner, CheckGlyph },
  props: {
    items: Array,
    db: Object,
    dbMaintenance: Object,
  },
  emits: ["dismiss"],
});
</script>

<i18n>
  en:
    maintenanceInProgress: "The database has been restored"
    text: "The backup has been successfully restored. You can now access your database safely."
  fr:
    maintenanceInProgress: "La base de données a été restaurée"
    text: "Le backup a été restaurée avec succès. Vous pouvez maintenant accéder à votre base de données en toute sécurité."
</i18n>
